.user-modal {
    width: 60vw ;   /* Occupy the 60% of the screen width */
    max-width: 90vw;
  }
  .table-responsive {
    height: 400px;
    width:100%;
    overflow:auto;
  }
  .table-responsive tbody tr {
    cursor: pointer;
  }
  .table-responsive .table thead tr th {
    border-collapse:collapse;
    border:1px solid #5900ff;
  }
  .table-responsive .table tbody tr td {
    border:1px solid #5900ff;
  }
  .div-text {
    color:red;
    font-size: 20px;
  }
  .reveal-if-active {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transform: scale(0.8);
    transition: 0.5s;
    input[type="radio"]:checked ~ &,
    input[type="checkbox"]:checked ~ & {
      opacity: 1;
      max-height: 100px;
      overflow: visible;
      padding: 10px 20px;
      transform: scale(1);
    }
  }

@border: 1px solid #D3D3D3;

/*** Overrides for react-virtualized styles ***/
.ReactVirtualized__Table__Grid {
    background-color: white;
    border-bottom: @border;
    border-right: @border;
}
.ReactVirtualized__Table__headerRow {
    background-image: linear-gradient(#fff, #efefef);
    border: @border;
    padding-right: 0 !important;
}
.ReactVirtualized__Table__row {
    border-bottom: @border;
    border-left: @border;
}

.ReactVirtualized__Table__headerColumn {
  align-items: center;
    border-right: @border;
    display: flex;
    flex: 1;
    height: 100%;
    min-width: 0;

    &:last-child {
        border-right: none;
    }
}

.ReactVirtualized__Table__rowColumn {
  align-items: center;
    border-right: @border;
    display: flex;
    flex: 1;
    height: 100%;
    min-width: 0;

    &:last-child {
        border-right: none;
    }
}

.bodyTable {
  overflow: auto;
  flex: 1 1 auto;
}