body {
	overflow-x:hidden;
	overflow-y:scroll;
	font-family: "Lato", "Helvetica Neue", "Arial", "Helvetica", "sans-serif";
	height:100%;
	z-index:-101;	
	background: url('../../assets/images/background.jpg') no-repeat center;
	background-size:cover; 
	background-attachment: fixed;
}

#globalContainer {
	min-height:100%;
	height:100%;
	height: auto !important;
}

video#bgvid {
	position: fixed; 
	right: 0; 
	bottom: 0;
	min-width: 100%; 
	min-height: 100%;
	width: auto; 
	height: auto; 
	background: url('../../assets/images/background.jpg') no-repeat center;
	background-size: cover;
	z-index:-100;
}

#globalHeader {
	padding:6px;
	top: 0;
	left: 0;
	width: 100%;
	display: table; 
	vertical-align:middle;	
}

#globalHeader > span {
	display:table-cell;
}

#globalHeader > nav {
	display:table-cell;
	vertical-align:middle; 
	text-align:right;
	padding-right: 10px;
}

#globalHeader > nav > a {
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 10px;
}

.left-half-slope {
    width: 100%;
    background: #fff;
    height: 200px;
    display: inline-block;
    -ms-transform: rotate(8deg); /* IE 9 */     
    -webkit-transform: rotate(8deg); /* Chrome, Safari, Opera */     
    transform: rotate(8deg);
    position: absolute;
    left: -50px;
    top: 90px;
  
}

.left-half-slope.bluebkg {
	background:#3B83C0;
}

.right-half-slope {
    width: 100%;
    background: #fff;
    height: 200px;
    display: inline-block; 
    -ms-transform: rotate(-8deg); /* IE 9 */     
    -webkit-transform: rotate(-8deg); /* Chrome, Safari, Opera */     
    transform: rotate(-8deg);
    position: absolute;
    right: -50px;
    top: 90px;
}

.right-half-slope.bluebkg {
	background:#3B83C0;
}


/*
.ui.darkred {
	background-color: #680C1E;
	color: #ffffff;
}
*/

/*
#globalHeader.shadow {
	-webkit-box-shadow: 0px 1px 4px 2px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 1px 4px 2px rgba(0,0,0,0.75);
	box-shadow: 0px 1px 4px 2px rgba(0,0,0,0.75);	
}
*/

#contentWrapper {
	display: block;
	/*min-width: 1000px;
	max-width: 1440px;*/
	/*z-index:2;*/
}

footer#globalFooter {
	width: 100%;
	vertical-align:bottom;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	position: relative;
	bottom: 0;	
	z-index:-1;
}

#copyright {
	font-family: "Source Sans Pro Regular";
	font-size: 14px;
	color: #ffffff;	
}

#version {
	font-family: "Source Sans Pro Regular";
	font-size: 12px;
	color: #ffffff;	
}

#splashContainer {
	margin-top:58px;
	margin-left: auto;
	margin-right: auto;
	width: 900px;
}

#splashContainer > span {
	display:table-cell;
	vertical-align: middle;
}

#subtext {
	font-family: "Source Sans Pro Regular";
	font-size: 18px;
	color: #ffffff;		
	margin-top:20px;
	display:table;
	width: 900px;
	border-spacing:20px;
}

#subtext > span {
	margin-left:10px;
	margin-right:10px;
	padding:20px;
	background-color: #3B83C0;
	width:280px;
	border-radius: 20px;
	display:table-cell;
}

.splash {
	font-family: "Lato", "Source Sans Pro Regular", "Helvetica Neue", "Arial", "Helvetica", "sans-serif";
	font-size: 36px;
	color: #ffffff;
	/*text-shadow: 0px 0px 4px #000000;*/
}

.splash-sub {
	font-family: "Source Sans Pro Regular";
	font-size: 18px;
	color: #ffffff;
}

#marketingTextContainer {
	width:100%;
	background-color:#3B83C0;
	z-index:1;
	position:relative;
}

.marketingText {
	font-family: "Source Sans Pro Regular";
	font-size: 16px;
	color: #ffffff;	
}

.marketingText.table {
	width:900px;
	display:table;
	margin-left:auto;
	margin-right:auto;
}

.marketingText.tableCell {
	display:table-cell;
	padding-left:40px;
	padding-right:40px;
	font-size: 18px;
}
.centeredText {
	text-align:center;	
}

.marketingText.subHead {
	display:inline-block;
	font-size: 24px;
	text-decoration: underline;
	padding-bottom:10px;
}

.marketingText.subHead2 {
	display:table;
	margin-left:auto;
	margin-right:auto;
	padding-top:30px;
	padding-bottom:30px;		
	font-size: 36px;
}

.marketingText > a {
	text-decoration:none;
	color: #ffffff;
}

.marketingText > a:hover {
	text-decoration:underline;
	color: #ffffff;
}


.loginForm {
	box-shadow: 0 0 6px 2px #888888;
 	width: 340px; 
 	margin-left: auto; 
 	margin-right: auto;	
}

#rrFormContainer {
	box-shadow: 0 0 6px 2px #888888;
 	width: 90%; 
 	min-width: 920px;
 	max-width: 1200px;
 	margin-left: auto; 
 	margin-right: auto;
 	background: #ffffff;	
}

.rosterSelectForm {
	box-shadow: 0 0 6px 2px #888888;
 	width: 576px; 
 	margin-left: auto; 
 	margin-right: auto;	
}

#rosterContainer {
	width:100%;
}

#rosterEntryFormContainer {
	width: 260px;
}

#rosterDataContainer {
	width: auto;
}

#rosterDataTable > thead > tr {
	white-space: nowrap;
}

#rosterSpacer {
	width: 20px;
}

#offeringDetailsContainer {
	z-index:9;
	height: 44px;
	overflow-y:hidden;
}

#offeringDetailsNav {
	position: relative; 
	top:-35px;
	z-index:10;	
	visibility: hidden;
}

#offeringDetailsNavLeft {
	display:none;
	float:left;
}

#offeringDetailsNavRight {
	display:inline-block;
	float:right;
}

div.table {
	display: table;	
}

div.tableRow {
	display: table-row;	
}

div.tableCell {
	display: table-cell;
}

div.tableCell.align.top {
	vertical-align:top;
}

div#rrCardContainer {
	padding-top:3px;	/*shift down 3px to compensate for the 3px box shadow at the top*/	
}

.paddlenav-arrow {
	padding-left: 6px;
	padding-right: 6px;
	text-align: center;
	overflow: hidden;
	color: #000;
	background-color: silver;
	border-radius: 4px;
	display: block;
	font-family: 'Arial Narrow';
	font-size: 24px;
	font-style: normal;
	-webkit-transition: 250ms opacity linear, 250ms color linear, 250ms background-color linear;
	transition: 250ms opacity linear, 250ms color linear, 250ms background-color linear;
	opacity: 0.7;
}

.paddlenav-arrow:hover {
  opacity: 1.0;
}

.fade-right {
    -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,1)), color-stop(0.88, rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    /*mask: url(#fade_right_svg_mask);*/
}

.fade-left {
    -webkit-mask-image: -webkit-gradient(linear, right top, left top, from(rgba(0,0,0,1)), color-stop(0.88, rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    /*mask: url(#fade_right_svg_mask);*/
}

.hscroll {  
	overflow-x: hidden;
	white-space: nowrap;
	display: block !important;
}

.scrollableResultsTable {
	display:block;
}

.scrollableResultsTable>tbody {
	width:100%;
	display: inline-block;
    overflow-y: auto;    
    overflow-x: hidden; 
}

	
.scrollableResultsTable>tbody#userProfileList {
    max-height: 160px;   
	-webkit-overflow-scrolling: touch;     
}


/* vvv styles borrowed and extended from semantic-ui vvv */
.ui.blue.cards.rr > .card,
.ui.cards > .blue.card.rr,
.ui.blue.card.rr {
	box-shadow: 0px -3px 0px 0px #3b83c0, 0px 3px 0px 0px #3b83c0, 0px 0px 0px 1px #d4d4d5;
	background:rgba(0,0,0,.03);
}

.ui.table.borderless {
	border:0;
}

.ui.table.borderless tr td {
	border-top: 0;
}

.ui.table.borderless tr td:first-child {
	font-weight:700;
	color: rgba(0,0,0,.8);
	width:148px;
}

.ui.table.borderless td {
	padding-left: 8px;
	padding-top: 0px;
	padding-bottom: 8px;
	padding-right:0px;
}

/* force dropdown to be closer to buttons and centered. as-is, it hovers a few pixels too far away */
.ui.top.right.pointing.dropdown>.menu {
	margin: 0.6em -0.25em 0;
}

.loader.standalone,
.loader > .standalone {
	display:block !important;
	color:#000 !important;
	padding: 8px;
}

.loader.standalone.centered,
.loader.standalone > .centered {
	text-align:center;
}

.loader.standalone:after,
.loader.standalone > .centered:after {
	border-color:#aaa transparent transparent !important;
}

.dimmed.dimmable>.ui.animating.dimmer.inline, .dimmed.dimmable>.ui.visible.dimmer.inline, .ui.active.dimmer.inline  {
	display: block;
	opacity: 0.7 !important;
	z-index:255;
}

.ui[class*="top aligned"].table tr > td {
	vertical-align:top;
}
/* ^^^ styles borrowed and extended from semantic-ui ^^^ */


.clearfix {
  overflow: auto;
}

/* JMR 11152016 Style added for tabs to support ad hoc feature */

ul.tab {
    list-style-type: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Float the list items side by side */
ul.tab li {
	float: left;
}

/* Style the links inside the list items */
ul.tab li a {
    background-color: inherit;
    font-weight: bold;
    color: black;
    font-size: 16px;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
}

/* Change background color of links on hover */
ul.tab li a:hover {
    background-color: #ddd;
}

ul.tab li a.active {
	background-color: #ccc;
}

/* Style the tab content */
.rosterSelectForm {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

hr { 
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
} 

.underline {
	text-decoration: underline;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #909;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #909;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #909;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #909;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #909;
}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }


/* Tooltips */

.info .tip{ 
  display: none;
  position: absolute;
  border: 1px solid #333;
  border-radius: 2px;
  padding: 3px;
  background: #FFF;
  width: 280px;
  text-indent: 70px;
  font-weight: normal;
  text-align: justify;
}

/* END JMR 11152016 Style added for tabs to support ad hoc feature */


.wrapper {
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width:400px;
  height:200px;
  background-color: white;
}




.previewSignOffSheet {
	#signature{
		margin:auto;
		display:block;
		background-color: rgb(224, 224, 224);
	}
	
	form {
		padding: 20px 12px 10px 20px;
		border: 1px solid #CCC;
		border-radius: 1em;
		font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
	overflow: auto;
	}
	
	table, th, td {
		border: 1px solid black;
		overflow: auto;
	}
}
